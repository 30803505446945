import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import ProviderListHome from "../../snippets/ProviderListHome";
import PaymentMethod from '../../snippets/PaymentMethod';
import { currentPath, currentDomain } from '../../App';
import { useTranslation } from 'react-i18next';

function Slots() {
    const [sliderCurrentPage, setSliderCurrentPage] = useState('all');
    const [bannerCurrentPage, setBannerCurrentPage] = useState('all');
    const { t } = useTranslation();
    const slotsBox = [
        { id: 'rtg', bgImg: "/images/slots/hero/rtg-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/rtg-logo-small.webp", btn: "/slots/rtg/", newGame: false, topGame: true },
        { id: 'spades', bgImg: "/images/slots/hero/spadegaming-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/spadegaming-logo-small.webp", btn: "/slots/spadegaming/", newGame: false, topGame: false },
        { id: 'jili', bgImg: "/images/slots/hero/jili-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/jili-logo-small.webp", btn: "/slots/jili/", newGame: false, topGame: false, promoGame: false },
        { id: 'bng', bgImg: "/images/slots/hero/bng-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/bng-logo-small.webp", btn: "/slots/bng/", newGame: false, topGame: false, promoGame: false},
        { id: 'bng', bgImg: "/images/slots/hero/btg-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/btg-logo-small.webp", btn: "/slots/btg/", newGame: false, topGame: false, promoGame: false},
        { id: 'playstar', bgImg: "/images/slots/hero/playstar-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/playstar-logo-small.webp", btn: "/slots/playstar/", newGame: false, topGame: false },
        // { id: 'playson', bgImg: "/images/slots/hero/playson-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/playson-logo-small.webp", btn: "/slots/playson/", newGame: false, topGame: false },
        { id: 'netent', bgImg: "/images/slots/hero/netent-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/netent-logo-small.webp", btn: "/slots/netent/", newGame: false, topGame: false },
        { id: 'pgsoft', bgImg: "/images/slots/hero/pgsoft-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/pg-soft-logo-small.webp", btn: "/slots/pgsoft/", newGame: false, topGame: true },
        { id: 'ygg', bgImg: "/images/slots/hero/ygg-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/ygg-logo-small.webp", btn: "/slots/ygg/", newGame: false, topGame: false },
        { id: 'redtiger', bgImg: "/images/slots/hero/redtiger-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/red-tiger-logo-small.webp", btn: "/slots/redtiger/", newGame: false, topGame: false },
        { id: 'cq9', bgImg: "/images/slots/hero/cq9-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/cq9-logo-small.webp", btn: "/slots/cq9/", newGame: false, topGame: false },
        { id: 'pragmatic', bgImg: "/images/slots/hero/pragmatic-play-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/pp.webp", btn: "/slots/pragmatic-play/", newGame: false, topGame: false, promoGame: true},
        { id: 'popok', bgImg: "/images/slots/hero/popok-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/popok-logo.webp", btn: "/slots/popok/", newGame: false, topGame: false },
        { id: 'kingmidas', bgImg: "/images/slots/hero/kingmaker-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/km-logo.webp", btn: "/slots/kingmidas/", newGame: false, topGame: false },
        { id: 'advantplay', bgImg: "/images/slots/hero/advantplay-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/advantplay-logo.webp", btn: "/slots/advantplay/", newGame: false, topGame: false },
        { id: 'betsoft', bgImg: "/images/slots/hero/betsoft-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/betsoft-logo.webp", btn: "/slots/betsoft/", newGame: false, topGame: false },
        { id: 'dragongaming', bgImg: "/images/slots/hero/dragongaming-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/dragongaming-logo.webp", btn: "/slots/dragongaming/", newGame: false, topGame: false },
        { id: 'gameplay', bgImg: "/images/slots/hero/gameplay-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/gameplay-logo.webp", btn: "/slots/gameplay/", newGame: false, topGame: false },
        { id: 'mannaplay', bgImg: "/images/slots/hero/manaplay-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/mannaplay-logo.webp", btn: "/slots/mannaplay/", newGame: false, topGame: false },
        { id: 'microgaming', bgImg: "/images/slots/hero/microgaming-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/microgaming-logo.webp", btn: "/slots/microgaming/", newGame: false, topGame: false },
        { id: 'playtech', bgImg: "/images/slots/hero/playtech-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/playtech-logo.webp", btn: "/slots/playtech/", newGame: false, topGame: false },
        { id: 'ttg', bgImg: "/images/slots/hero/toptrend-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/ttg-logo.webp", btn: "/slots/ttg/", newGame: false, topGame: false },
        { id: 'yl', bgImg: "/images/slots/hero/yilian-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/yl-logo.webp", btn: "/slots/yl/", newGame: false, topGame: false },
        { id: 'evolution', bgImg: "/images/slots/hero/evolution-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/evolution-logo.webp", btn: "/slots/evolution/", newGame: false, topGame: false },
        // { id: 'nlc', bgImg: "/images/slots/hero/nlc-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/no-limit-city-logo-small.webp", btn: "/slots/nlc/", newGame: true, topGame: false },
        // { id: 'nextspin', bgImg: "/images/slots/hero/nextspin-at-sg8-mobile.webp", logoImg: "/images/slots/small-logo/nextspin-logo-small.webp", btn: "/slots/nextspin/", newGame: true, topGame: false },
    ];

    // Sort the slotsBox array to put promo games first, then top games, then new games, then alphabetically by 'id'
    slotsBox.sort((a, b) => {
        if (a.id === 'playtech') return 1;
        if (b.id === 'playtech') return -1;

        if (a.promoGame && !b.promoGame) return -1;
        if (!a.promoGame && b.promoGame) return 1;

        if (a.topGame && !b.topGame) return -1;
        if (!a.topGame && b.topGame) return 1;

        if (a.newGame && !b.newGame) return -1;
        if (!a.newGame && b.newGame) return 1;

        return a.id.localeCompare(b.id);
    });

    const slotItem = slotsBox.map((data) => (
        <div className='slotsBox' style={{ backgroundImage: `url(${data.bgImg})` }} key={data.id}>
            {data.topGame && <div className='top-label'>{t('header.8')}</div>}
            {data.newGame && <div className='new-label'>{t('header.9')}</div>}
            {data.promoGame && <div className='promo-label'>{t('header.10')}</div>}
            <img src={data.logoImg} alt={`${data.id} logo`} />
            <a href={data.btn} className='yellow-btn slots-selector-btn'>{t('button.1')}</a>
        </div>
    ));

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SG8Bet</title>
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Discover the best online slot games of 2024 at SG8Bet. Enjoy high-quality slots, innovative gameplay, and thrilling wins. Join now and start playing!" />
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
            <div className="main-wrapper">
                <div className="main-content-area">
                    <MainSlider sliderCurrentPage={sliderCurrentPage} bannerCurrentPage={bannerCurrentPage} />
                    <ProviderListHome />
                    <div className='slotsProviderWrapper'>
                        {slotItem}
                    </div>
                </div>
            </div>
            <div className="main-content-wrapper">
                <div className="text-content-area">
                </div>
            </div>
        </>
    );
}

export default Slots;
