import React from 'react'
 
export default function LineQRCode() {
  return (
    <div id="topbar"> 
      <a className='desktop-line-qr' target="_blank" href="https://line.me/ti/p/FHqWvvfLJc">
        <img src="/images/Line-QR.webp"/>
      </a> 
    <div className="border-text">SG8 FUN
    </div>
    </div>
   )
}

 