import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiJSONURL } from '../pages/LiveCasino';
import { useTranslation } from 'react-i18next';
export const dataLink = process.env.REACT_APP_DATA;

const Login = () => {
  const { t, i18n } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [closeOtpForm, setCloseOtpForm] = useState(false);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [showPassword,setShowPassword] = useState(false);

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleShowHide = () =>
  {
    setShowPassword((prev) => !prev);
  }
  async function encrypt(text) {
    const response = await axios.post(
      apiJSONURL,
      null,
      {
        params: {
          cmd: "getKey",
        },
      }
    );
    const key = response.data.key;
    const iv = key;
    const encodedText = new TextEncoder().encode(text);
    const cryptoKey = await window.crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(key),
      { name: 'AES-CBC' },
      false,
      ['encrypt']
    );
    const encrypted = await window.crypto.subtle.encrypt(
      { name: 'AES-CBC', iv: new TextEncoder().encode(iv) },
      cryptoKey,
      encodedText
    );
    return new Uint8Array(encrypted);
  }
  
  function uint8ArrayToBase64(uint8Array) {
    let binary = '';
    const len = uint8Array.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(uint8Array[i]);
    }
    return btoa(binary);
  }

    async function getEncryptedUsername(data){
      try
{      if (localStorage.getItem("username")){
          await encrypt(data).then(encrypted => {
          localStorage.setItem('encryptedUsername', uint8ArrayToBase64(encrypted));
            console.log("EUN "+ uint8ArrayToBase64(encrypted));
          });
      }}
      catch (error) {
        console.error("Failed to get the key:", error);
    }
      }

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn") === "true";
    const savedUsername = localStorage.getItem("username");
    const savedRememberMe = localStorage.getItem("rememberMe") === "true";

    if (savedRememberMe && loggedInStatus && savedUsername) {
      setIsLoggedIn(true);
      setUsername(savedUsername);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(dataLink, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          cmd: "loginjs",
          u: username,
          p: password,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      if (data.username){
        await encrypt(data.username).then(encrypted => {
          localStorage.setItem('encryptedUsername', uint8ArrayToBase64(encrypted));
            console.log("EUN "+ uint8ArrayToBase64(encrypted));
        });}
      handleResponse(data);
    } catch (error) {
      setErrorMessage(
        "There was a problem with the login operation. Please try again."
      );
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleResponse = (data) => {
    if (data.s === "success") {
      if (rememberMe) {
        localStorage.setItem("username", data.username);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("rememberMe", "true");
      }
      localStorage.setItem("username", data.username);
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("rememberMe", "true");
      setUsername(data.username);
      setIsLoggedIn(true);
      setErrorMessage("");
      window.location.href = "/";
    } else {
      setErrorMessage(data.emsg || "Login failed. Please try again.");
      console.error("Login failed:", data);
    }
  };

  const handleOtpClick = () => {
    setShowOtpForm(true);
    setCloseOtpForm(true);
  };

  const handleCloseOtpClick = () => {
    setShowOtpForm(false);
    setCloseOtpForm(false);
    setOtpSuccess(false);
  };

  const handleInputChange = (e) => {
    setMobile(e.target.value);
  };

  const getOtp = async () => {
    try {
      const response = await axios.post(dataLink,
        null,
        {
          params: {
            cmd: "getOTP",
            mobile: mobile,
          },
        }
      );
      if (response.data.s) {
        setOtpSuccess(true); // Show success message
      }
      console.log(response.data); // Handle the response as needed
    } catch (error) {
      console.error("Error getting OTP:", error);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(dataLink,
        null,
        {
          params: {
            cmd: "changepasswordWithOTP",
            mobile: mobile,
            otp: otp,
            password: newPassword,
          },
        }
      );

      if (response.data.s) {
        setErrorMessage(
          "Password changed successfully, please proceed to login with your new password."
        );
        setOtpSuccess(false);
        setShowOtpForm(false);
        setCloseOtpForm(false);
      } else {
        setErrorMessage(response.data.e || "Error changing password.");
      }
    } catch (error) {
      setErrorMessage(
        "There was an error changing the password. Please try again."
      );
      console.error("Error changing password:", error);
    }
  };

  return (
    <div>
      {errorMessage && (
        <p
          style={{ position: "relative", fontSize: "0.8em", color: "#d3d3d3" }}
        >
          {errorMessage}
        </p>
      )}
      <form className="form-login" onSubmit={handleLogin}>
        <label>{t('login.1')}</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder={t('login.1')}
        />
        <label>
        {t('login.2')}
        </label><div className="password-container">
        <input
          type= {showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={t('login.2')}
        />
        <img className='showhide' src={showPassword ? "/images/icons/hide.webp" : "/images/icons/view.webp"} onClick={handleShowHide}/>
        </div>
        <label>
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={handleRememberMe}
          />
          {t('login.3')}
        </label>
        <button type="submit">{t('header.1')}</button>
        <button
          type="button"
          className="forgetpw-trigger"
          onClick={handleOtpClick}
        >
          {t('login.4')}
        </button>
      </form>
      {showOtpForm && (
        <div className="formotp">
          <span className="notice">
            {t('login.6')}
          </span>
          <label>{t('login.7')}</label>
          <input
            type="text"
            value={mobile}
            onChange={handleInputChange}
            placeholder="Enter mobile number"
          />
          <button onClick={getOtp}>{t('header.7')}</button>
        </div>
      )}
      {otpSuccess && (
        <div className="success-otp">
          <label className="otplabel">OTP</label>
          <input
            className="otpinput"
            type="text"
            placeholder="OTP"
            autoComplete="off"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <label>New Password</label>
          <input
            type="password"
            placeholder="New Password"
            autoComplete="off"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <label>Confirm New Password</label>
          <input
            type="password"
            placeholder="Confirm New Password"
            autoComplete="off"
            required
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
          <button onClick={handleChangePassword}>Change Password</button>
        </div>
      )}
      {closeOtpForm && (
        <button className="otp-close" onClick={handleCloseOtpClick}>
          ✖
        </button>
      )}
    </div>
  );
};

export default Login;
