import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import { currentPath, currentDomain } from "../../App";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../cookies";

export default function ExtraBonus() {
  const { t, i18n } = useTranslation();
  const [isThaiLanguage, setIsThaiLanguage] = useState(getCookie('i18next') === 'th');
  const title = "Extra Bonus, Extra Fun";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("ExtraBonusExtraFun");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("ExtraBonusExtraFun");
  const [loading, setLoading] = useState(true);
  
 // Watch for changes in the cookie
  useEffect(() => {
    const checkLanguageChange = () => {
      const currentLanguage = getCookie('i18next') === 'th';
      if (currentLanguage !== isThaiLanguage) {
        setIsThaiLanguage(currentLanguage);
      }
    };

    // Set up a timer to check the cookie periodically
    const intervalId = setInterval(checkLanguageChange, 1000); // Check every second

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [isThaiLanguage]);

  // const handleLinkClick = () => {
  //   window.open(eventLink);
  //   };

  const enContent = (
    <>
      <h2>Extra Bonus, Extra Fun</h2>
      <p>Daily Rewards for You!
      </p>
      <br />
      <h2>Promotion Eligibility</h2>
      <ol>
        <li>      This promotion is available to the existing players who deposit a minimum of 300 THB.</li>
        <li>A player can claim only once a day.</li>
      </ol>
      <h2>
      Bonus Structure
      </h2>
      <ol>
      <li>Players who deposit at least 300 THB will receive a 20% bonus on their deposit.</li>
      <li>The maximum bonus amount awarded per deposit is set at a maximum cap of 2,000 THB. Bonus funds will be credited automatically after a successful deposit.</li>
      <li>The maximum cap of deposit is 10,000THB. </li>
      </ol>
      <h2>
      Wagering Turnover Requirements
      </h2>
      <ol>
        <li>
      The turnover requirement is 10x of the bonus amount.
      <br/>
      <b>Example:</b>
      <br/>
DEPOSIT: 300THB
<br/>
BONUS: 20% of 300THB = 60THB
<br/>
TURNOVER: 60THB x 10= 600THB (minimum rollover)
</li>
<div className="table-main">
<table>
<thead>
                  <th>Amount Deposit</th>
                  <th>Bonus (20%)</th>
                  <th>Turnover</th>
                </thead>
                <tbody>
                  <tr>
                    <td>300 THB</td>
                    <td>60 THB</td>
                    <td>x10 (3,600)</td>
                  </tr>
                  <tr>
                    <td>10,000 THB</td>
                    <td>2,000 THB <br/> Maximum Cap</td>
                    <td>x10 (20,000)</td>
                  </tr>
                  </tbody>
</table>
</div>
<li>
The turnover requirement must be met before withdrawal.
</li>
      </ol>
      <h2>Wagering Restrictions
      </h2>
      <ol>
        <li>
        Only wagers made with bonus funds will count towards fulfilling the wagering requirements.
        </li>
        <li>
        All wagers must be placed on real money games or games eligible under the bonus terms.
        </li>
      </ol>
    </>
  );

  const thContent = (
    <>
      <h2>โบนัสพิเศษ ความสนุกพิเศษ</h2>
      <p>รางวัลรายวันสำหรับคุณ!
      </p>
      <br />
      <h2>สิทธิ์ในการเลื่อนตำแหน่ง</h2>
      <ol>
        <li>      โปรโมชั่นนี้มีให้สำหรับผู้เล่นที่มีอยู่ที่ฝากเงินขั้นต่ำ 300 บาท</li>
        <li>ผู้เล่นสามารถขอรับโบนัสได้เพียงวันละ 1 ครั้ง</li>
      </ol>
      <h2>
      โครงสร้างโบนัส
      </h2>
      <ol>
      <li>ผู้เล่นที่ฝากเงินขั้นต่ำ 300 บาท จะได้รับโบนัส 20% จากยอดฝากของพวกเขา</li>
      <li>จำนวนโบนัสสูงสุดที่สามารถได้รับต่อการฝากเงินคือ 2,000 บาท
      โบนัสจะถูกเครดิตเข้าสู่บัญชีโดยอัตโนมัติเมื่อการฝากเงินสำเร็จ
</li>
      <li>ขีดจำกัดสูงสุดของการฝากเงินคือ 10,000 บาท </li>
      </ol>
      <h2>
      ข้อกำหนดการหมุนเวียนเดิมพัน
      </h2>
      <ol>
        <li>
        ข้อกำหนดการหมุนเวียนคือ 10 เท่าของจำนวนโบนัส
      <br/>
      <b>ตัวอย่าง:</b>
      <br/>
      ฝากเงิน: 300 บาท
<br/>
โบนัส: 20% ของ 300 บาท = 60 บาท
<br/>
การหมุนเวียน: 60 บาท x 10 = 600 บาท (การหมุนเวียนขั้นต่ำ)
</li>
<div className="table-main">
<table>
<thead>
                  <th>Amount Deposit</th>
                  <th>Bonus (20%)</th>
                  <th>Turnover</th>
                </thead>
                <tbody>
                  <tr>
                    <td>300 THB</td>
                    <td>60 THB</td>
                    <td>x10 (3,600)</td>
                  </tr>
                  <tr>
                    <td>10,000 THB</td>
                    <td>2,000 THB <br/> Maximum Cap</td>
                    <td>x10 (20,000)</td>
                  </tr>
                  </tbody>
</table>
</div>
<li>
ข้อกำหนดการหมุนเวียนต้องได้รับการปฏิบัติก่อนที่จะทำการถอนเงิน
</li>
      </ol>
      <h2>ข้อจำกัดการเดิมพัน
      </h2>
      <ol>
        <li>
        การเดิมพันที่ทำด้วยโบนัสเท่านั้นที่จะนับเป็นการปฏิบัติตามข้อกำหนดการหมุนเวียน
        </li>
        <li>
        การเดิมพันทั้งหมดต้องวางบนเกมที่ใช้เงินจริงหรือเกมที่มีสิทธิ์ตามข้อกำหนดของโบนัส
        </li>
      </ol>
    </>
  );

 
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Extra Bonus, Extra Fun</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="SG8Bet with Extra Bonus, Extra Fun!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />

        <div className="main-wrapper-promo">
          <div className="main-content-area">
            {isThaiLanguage ? thContent : enContent}
            {/* <br/>
            <a onClick={handleLinkClick} className="yellow-btn">VIEW FULL T&C</a> */}
          </div>
        </div>
      </div>
    </>
  );
}
