import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import { currentPath, currentDomain } from "../../App";
import { getCookie } from "../../cookies";

export default function PlayAndWin() {
  const title = "Spade Gaming Play and Win";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("PlayAndWin");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("PlayAndWin");
  
  // Track language in state
  const [isThaiLanguage, setIsThaiLanguage] = useState(getCookie('i18next') === 'th');

  // Watch for changes in the cookie
  useEffect(() => {
    const checkLanguageChange = () => {
      const currentLanguage = getCookie('i18next') === 'th';
      if (currentLanguage !== isThaiLanguage) {
        setIsThaiLanguage(currentLanguage);
      }
    };

    // Set up a timer to check the cookie periodically
    const intervalId = setInterval(checkLanguageChange, 1000); // Check every second

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [isThaiLanguage]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spade Gaming Play and Win</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Spade Gaming Play and Win"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        
        {!isThaiLanguage && (
            <div className="main-wrapper-promo">
                <div className="main-content-area">
                    <h2>Terms & Conditions</h2>
                    <ul>
                        <li>If a player appears on more than one leaderboard, the player will be able to win more than one prize. The prize will be remitted in 72 hours after each tournament round ends, please check changes in the player’s wallet balance.</li>
                        <li>Players will be able to check their rank, rules, and terms through the “Tournament” icon available in games.</li>
                        <li>If there are two or more players with identical win scores, the player who scores it first will get a higher position on the leaderboard.</li>
                        <li>Spadegaming reserves the right to disqualify a player or group of players if being found to use dishonest methods, cheat or intimidate other players/operators, or found to be abusing system loopholes to affect the result.</li>
                        <li>Spadegaming reserves the right to modify the activity terms and conditions without prior notice.</li>
                    </ul>
                    <div style={{ height: "20px" }}></div>
                    <a href="https://spade-event.com/enews/event/2025_PlayWin/?lang=en-US" className="yellow-btn">VIEW FULL T&C</a>
                </div>
            </div>
        )}
        
        {isThaiLanguage && (
            <div className="main-wrapper-promo">
            <div className="main-content-area">
                <h2>ข้อกำหนดและเงื่อนไข</h2>
                <ul>
                <li>หากผู้เล่นปรากฏในลีดเดอร์บอร์ดมากกว่า 1 รายการ ผู้เล่นมีสิทธิ์ชนะรางวัลได้มากกว่า 1 รางวัล โดยรางวัลจะถูกส่งให้ภายใน 72 ชั่วโมงหลังจากแต่ละรอบทัวร์นาเมนต์สิ้นสุดลง โปรดตรวจสอบการเปลี่ยนแปลงในยอดคงเหลือในวอลเล็ตของผู้เล่น</li>
                <li>ผู้เล่นสามารถตรวจสอบอันดับ กฎ และเงื่อนไขผ่านไอคอน “ทัวร์นาเมนต์” ที่ปรากฎอยู่ในเกม</li>
                <li>หากมีผู้เล่น 2 คนขึ้นไปที่มีคะแนนชนะเท่ากัน ผู้เล่นที่ทำคะแนนได้ก่อนจะได้รับตำแหน่งที่สูงขึ้นในลีดเดอร์บอร์ด.</li>
                <li>Spadegaming ขอสงวนสิทธิ์ในการตัดสิทธิ์ผู้เล่นหรือกลุ่มผู้เล่นหากพบว่าผู้เล่นหรือกลุ่มผู้เล่นใช้วิธีการที่ไม่สุจริต โกงหรือข่มขู่ผู้เล่น / ผู้ดำเนินการรายอื่นหรือพบว่าใช้ช่องโหว่ของระบบเพื่อส่งผลกระทบต่อกระดานผู้นำ</li>
                <li>Spadegaming ขอสงวนสิทธิ์ในการแก้ไขข้อกำหนดและเงื่อนไขของกิจกรรมโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</li>
                </ul>
                <div style={{ height: "20px" }}></div>
                <a href="https://spade-event.com/enews/event/2025_PlayWin/?lang=th-TH" className="yellow-btn">ข้อกำหนดและเงื่อนไข</a>
            </div>
            </div>
        )}
      </div>
    </>
  );
}