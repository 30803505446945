import React from 'react'
 
export default function LineQr() {
  return (
   <div className="line-wrapper">
      <a className='mobile-icon-qr' target="_blank" href="https://line.me/ti/p/FHqWvvfLJc">
        <img src="/images/line.png" alt="QR"/>
      </a>
     </div> 
   )
  }